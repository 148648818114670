import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'global';
import {MenuItems} from '../shared/menu-items/menu-items';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable()
export class AuthService {
  token: string;

  constructor(private router: Router,private httpClient: HttpClient,public MenuItems:MenuItems) {}

 

  loginUser(form) {
      localStorage.removeItem('token');
      this.httpClient.post(GlobalVariable._API_URL_+'admin/login',{usuario:form.usuario,contrasena:form.contrasena}).subscribe((data:any) => {
                    if(data.result == 1){
                      
                         localStorage.setItem('token',data.token);
                          localStorage.setItem('permisos',data.permisos);
                          localStorage.setItem('rol',data.rol);


                          if(JSON.parse(localStorage.getItem('rol')) != null){
                            const menu2 = JSON.parse(localStorage.getItem('rol')); 
                          }
                        
                          // this.MenuItems.getMenuitem();
                          this.router.navigate(['home']);

                          if(data.permision == null)
                             this.router.navigate(['home']);
                          else{
                              if(data.permision == 1){
                                this.router.navigate(['home']);
                              }
                          }
                          
                          
                    }
           });

  }

  logout() {   
    this.token = null;
  }

  getToken() {    
    return localStorage.getItem('token');
  }

  isAuthenticated() {
    return true;
  }
}
