import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { HomeComponent } from './home/home.component';
import { AjustesComponent } from './ajustes/ajustes.component';


export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/authentication/login',
        pathMatch: 'full'
      },
      {
        path: 'home',
        redirectTo: '/dashboards/dashboard1',
        pathMatch: 'full'
      },
      {
        path: 'dashboards',
        loadChildren: './dashboards/dashboards.module#DashboardsModule'
      },
      {
        path: 'ajustes',
        component: AjustesComponent
      },
      {
        path: 'secciones',
        loadChildren:
          './secciones/secciones.module#SeccionesComponentsModule'
      },
      {
        path: 'noticias',
        loadChildren:
          './noticias/noticias.module#NoticiasComponentsModule'
      },
      {
        path: 'servicios',
        loadChildren:
          './servicios/servicios.module#ServiciosComponentsModule'
      },
      {
        path: 'opciones',
        loadChildren:
          './opciones/opciones.module#OpcionesComponentsModule'
      },
      {
        path: 'personal',
        loadChildren:
          './personal/personal.module#PersonalComponentsModule'
      },
      {
        path: 'cabeceras',
        loadChildren:
          './cabeceras/cabeceras.module#CabecerasComponentsModule'
      },
      {
        path: 'sliders',
        loadChildren:
          './sliders/sliders.module#SlidersComponentsModule'
      },
      {
        path: 'menus',
        loadChildren:
          './menus/menus.module#MenusComponentsModule'
      },
    ]
  },
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'authentication/404'
  }
];
