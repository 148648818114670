import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  permisos?: {};
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const menu2 = {
  personal: 0,
  secciones: 0,
  ajustes: 0,
  servicios: 0,
  noticias: 0,
  menus: 0,
  sliders: 0,
  cabeceras: 0
};

console.log(menu2);

const MENUITEMS = [
  {
    state: 'secciones',
    name: 'Secciones',
    type: 'link',
    icon: 'dashboard',
    permisos: menu2.secciones
  },
  {
    state: 'servicios',
    name: 'Servicios',
    type: 'link',
    icon: 'assignment',
    permisos: menu2.servicios
  },
  {
    state: 'noticias',
    name: 'Noticias',
    type: 'link',
    icon: 'contact_mail',
    permisos: menu2.noticias
  },
  {
    state: 'menus',
    name: 'Menus',
    type: 'link',
    icon: 'calendar_view_day',
    permisos: menu2.menus
  },
  {
    state: 'cabeceras',
    name: 'Cabeceras',
    type: 'link',
    icon: 'chrome_reader_mode',
    permisos: menu2.cabeceras
  },
  {
    state: 'sliders',
    name: 'Sliders',
    type: 'link',
    icon: 'card_membership',
    permisos: menu2.sliders
  }
];

// content_copy



@Injectable()
export class MenuItems {
  menu2: {};
  getMenuitem(): Menu[] {



    return MENUITEMS;
  }
}
